// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://dev.k3s.smartschedules.biz',
  gMapsApiKey: 'AIzaSyDWlUUQW045s_ZSVk7mCUEl4AOh1TsQ3QI',
  tenantName: 'zim-slupsk-pl',
  keycloak: {
    issuer: 'https://keycloak.k3s.smartschedules.biz/auth/',
    realm: 'dev',
    clientId: 'ss-client',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
