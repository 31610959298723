import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { MaterialBarrelModule } from '../material-barrel/material-barrel.module';
import { CommonUiModule } from '../common-ui/common-ui.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { RouterModule } from '@angular/router';
import { TenantsModule } from '../tenants/tenants.module';
import { SchedulesModule } from '../schedules/schedules.module';
import { ClrIconModule } from "@clr/angular";
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [HomeComponent, SidenavComponent, FooterComponent],
  exports: [HomeComponent, FooterComponent],
  imports: [CommonModule, CommonUiModule, MaterialBarrelModule, RouterModule, TenantsModule, SchedulesModule, ClrIconModule, SharedModule,],
  providers: [],
})
export class HomeModule {
}
