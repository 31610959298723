import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { LocationState } from '../../state/location.state';
import { RootLocation } from '../../state/app.state.interfaces';
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Select(LocationState.location) location$: Observable<RootLocation>;
  @Select(LocationState.scheduleUuid) scheduleUuid$: Observable<string>;
  @Select(LocationState.routeUuid) routeUuid$: Observable<string>;

  readonly Location = RootLocation;

  constructor(private keycloakService: KeycloakService) {
  }

  logout() {
    this.keycloakService.logout();
  }
}
