import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FixLinkPipe } from './fix-link.pipe';
import { BigLogoComponent } from './big-logo/big-logo.component';
import { SelfLinkPipe } from './self-link.pipe';
import { SelfLink2Pipe } from './self-link2.pipe';
import { DirectionPipe } from './direction.pipe';
import { ClarityModule, ClrIconModule } from "@clr/angular";
import { DatagridModule } from "../datagrid/datagrid.module";
import { CdsModule } from '@cds/angular';

@NgModule({
  declarations: [FixLinkPipe, BigLogoComponent, SelfLinkPipe, SelfLink2Pipe, DirectionPipe],
  providers: [SelfLinkPipe, SelfLink2Pipe],
  imports: [CommonModule, ClrIconModule, DatagridModule, ClarityModule, CdsModule],
  exports: [FixLinkPipe, SelfLinkPipe, SelfLink2Pipe, DirectionPipe, ClrIconModule, DatagridModule, ClarityModule, CdsModule],
})
export class CommonUiModule {}
