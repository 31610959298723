import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulesComponent } from './schedules/schedules.component';
import { MaterialBarrelModule } from '../material-barrel/material-barrel.module';
import { RouterModule } from '@angular/router';
import { DummyComponent } from './dummy/dummy.component';
import { CommonUiModule } from "../common-ui/common-ui.module";
import { ReactiveFormsModule } from "@angular/forms";
import { ClarityModule } from "@clr/angular";

@NgModule({
  declarations: [SchedulesComponent, DummyComponent],
  imports: [
    CommonModule,
    MaterialBarrelModule,
    RouterModule,
    CommonUiModule,
    ReactiveFormsModule
  ],
  exports: [SchedulesComponent],
})
export class SchedulesModule {
}
