import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-big-logo',
  templateUrl: './big-logo.component.html',
  styleUrls: ['./big-logo.component.scss'],
})
export class BigLogoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
