import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialBarrelModule } from '../material-barrel/material-barrel.module';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';

@NgModule({
  declarations: [],
  imports: [CommonModule, MaterialBarrelModule, RouterModule, ClarityModule],
  exports: [ClarityModule],
})
export class DatagridModule {}
