import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SchedulesComponent } from '../schedules/schedules/schedules.component';
import { GoogleOnboardingProcessComponent } from '../google-onboarding-process/google-onboarding-process/google-onboarding-process.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: SchedulesComponent,
  },
  {
    path: 'google-onboarding-process',
    loadChildren: () => import('../google-onboarding-process/google-onboarding-process.module').then((m) => m.GoogleOnboardingProcessModule),
  },
  {
    path: ':scheduleUuid',
    children: [
      {
        path: 'routes',
        loadChildren: () => import('../bus-routes/bus-routes.module').then((m) => m.BusRoutesModule),
      },
      {
        path: 'stops',
        loadChildren: () => import('../stops/stops.module').then((m) => m.StopsModule),
      },
      {
        path: 'trips',
        loadChildren: () => import('../bus-trips/bus-trips.module').then((m) => m.BusTripsModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('../calendar/calendar.module').then((m) => m.CalendarModule),
      },
      {
        path: 'shapes',
        loadChildren: () => import('../shapes/shapes.module').then((m) => m.ShapesModule),
      },
      {
        path: 'stop-times',
        loadChildren: () => import('../stop-times/stop-times.module').then((m) => m.StopTimesModule),
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy',
    enableTracing: true
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
