<div class="main-container">
<!--  global alert notification -->
  <div class="alert alert-app-level alert-info" *ngIf="false">
    <div class="alert-items">
      <div class="alert-item static">
        <div class="alert-icon-wrapper">
          <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
        </div>
        <div class="alert-text">
          App Level Alert
        </div>
        <div class="alert-actions">
          <button class="btn btn-sm alert-action">Action</button>
        </div>
      </div>
    </div>
    <button type="button" class="close" aria-label="Close">
      <clr-icon aria-hidden="true" shape="close"></clr-icon>
    </button>
  </div>
  <!-- <header class="header header-6">
    <div class="branding">
      <a [routerLink]="['/']">
        <img src="assets/Logo-Color-sans-text.svg" class="logo" />
        <span class="title">Smart Schedules</span>
      </a>
    </div>
    <form class="search">
      <label for="search-input-sidenav-ng">
        <input id="search-input-sidenav-ng" type="text" placeholder="Search for keywords..." />
      </label>
    </form> -->
<!--    todo add selected schedule info -->
    <!-- <div class="settings">
      {{ location$ | async }}: {{ scheduleName$ | async }}/{{ routeUuid$ | async }}
      <a href="javascript://" class="nav-link nav-icon" title="Settings">
        <clr-icon shape="cog"></clr-icon>
      </a>
      <a (click)="logout()" class="nav-link nav-icon" href="javascript://" title="Logout">
        <clr-icon shape="logout"></clr-icon>
      </a>
    </div>
  </header> -->
<!--  sub head nav for bigger parts of navigation -->
  <nav class="subnav" *ngIf="false">
    <ul class="nav">
      <li class="nav-item">
        <a class="nav-link active" href="javascript://">Subnav Link 1</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript://">Subnav Link 2</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript://">Subnav Link 3</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript://">Subnav Link 4</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="javascript://">Subnav Link 5</a>
      </li>
    </ul>
  </nav>
  <div class="content-container">
    <div class="content-area">
      <header class="header header-6">
        <div class="branding">
          <!-- <a [routerLink]="['/']">
            <img src="assets/Logo-Color-sans-text.svg" class="logo" />
            <span class="title">Smart Schedules</span>
          </a> -->
          <h5 class="header__title">Welcome in Smart Schedules!</h5>
        </div>
        <form class="search">
          <label for="search-input-sidenav-ng">
            <input id="search-input-sidenav-ng" type="text" placeholder="Search for keywords..." />
          </label>
        </form>
    <!--    todo add selected schedule info -->
        <!-- <div class="settings">
          {{ location$ | async }}: {{ scheduleName$ | async }}/{{ routeUuid$ | async }}
          <a href="javascript://" class="nav-link nav-icon" title="Settings">
            <clr-icon shape="cog"></clr-icon>
          </a>
          <a (click)="logout()" class="nav-link nav-icon" href="javascript://" title="Logout">
            <clr-icon shape="logout"></clr-icon>
          </a>
        </div> -->
      </header>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <app-footer class="footer"></app-footer>
    </div>

    <app-main-navigation class="sidenav"></app-main-navigation>
<!--    side navigation -->
    <!-- <nav class="sidenav">
      <section class="sidenav-content" *ngIf="false">
        <section class="nav-group">
          <input id="tabexample2" type="checkbox" />
          <label for="tabexample2">Sidenav</label>
          <ul class="nav-list">
            <li><a class="nav-link">Link 1</a></li>
            <li><a class="nav-link">Link 2</a></li>
            <li><a class="nav-link active">Link 3</a></li>
            <li><a class="nav-link">Link 4</a></li>
            <li><a class="nav-link">Link 5</a></li>
            <li><a class="nav-link">Link 6</a></li>
          </ul>
        </section>
      </section>

      <app-sidenav class="sidenav"></app-sidenav>
    </nav> -->
  </div>
</div>
