import { Pipe, PipeTransform } from '@angular/core';
import { IHateoas } from '../core/ihateoas';

@Pipe({
  name: 'selfLink',
})
export class SelfLinkPipe implements PipeTransform {
  transform(entity: IHateoas, ...args: any[]): string {
    const self = entity._links.self.href || '';
    const chopped = self.split('/');
    return chopped.pop();
  }
}
