import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SchedulesStateModel } from './app.state.interfaces';
import { ISchedule, IScheduleCore } from '../core/ischedule';
import { GetAllSchedules, SetSchedule } from './app.state.actions';
import { tap } from 'rxjs/operators';
import { ScheduleService } from '../schedules/schedule.service';
import { Injectable } from '@angular/core';

@State<SchedulesStateModel>({
  name: 'Schedules',
})
@Injectable()
export class SchedulesState {
  constructor(private schedulesService: ScheduleService) {
  }

  @Selector()
  static loading(state: SchedulesStateModel): boolean {
    return state.loading;
  }

  @Selector()
  static schedule(state: SchedulesStateModel): IScheduleCore {
    return {
      uuid: state.scheduleUuid,
      name: state.scheduleName,
    };
  }

  @Selector()
  static schedules(state: SchedulesStateModel): ISchedule[] {
    return state.schedules;
  }

  @Selector()
  static scheduleUuid(state: SchedulesStateModel): string {
    return state.scheduleUuid;
  }

  @Selector()
  static scheduleByUuid(state: SchedulesStateModel): ISchedule {
    return state.schedules.filter(_ => _.uuid === state.scheduleUuid)[0];
  }

  @Selector()
  static scheduleIdByUuid(state: SchedulesStateModel): number {
    return state.schedules.filter(_ => _.uuid === state.scheduleUuid)[0].id;
  }

  @Selector([SchedulesState.scheduleUuid, SchedulesState.schedules])
  static scheduleName(state: SchedulesStateModel, uuid: string, schedules: ISchedule[] = []): string {
    const schedule: ISchedule = schedules.find((item) => item.uuid === uuid);
    return schedule ? schedule.name : null;
  }

  @Action(GetAllSchedules) getAllSchedules(ctx: StateContext<SchedulesStateModel>, action: GetAllSchedules) {
    ctx.patchState({
      loading: true
    });
    return this.schedulesService.all()
      .pipe(tap((schedules) => ctx.patchState({schedules, loading: false})));
  }

  @Action(SetSchedule, {cancelUncompleted: true}) setSchedule(
    ctx: StateContext<SchedulesStateModel>,
    action: SetSchedule,
  ) {
    ctx.patchState({
      scheduleUuid: action.payload,
    });
  }
}
