<div class="schedules">

  <h1 class="schedules__title">Your schedules</h1>

  <div class="schedules__buttons">
    <button class="btn btn-sm btn-primary" action="solid"
            (click)="addNewSchedule()"
    ><cds-icon shape="plus-circle" class="is-solid"></cds-icon> Add Schedule</button>
    <button class="btn btn-sm btn-info-outline" [routerLink]="['/google-onboarding-process']">
      <cds-icon shape="map" class="is-solid"></cds-icon> Google Maps Onboarding Process</button>

  </div>
  <div class="schedules__buttons">
    <button class="btn btn-sm btn-info-outline"
            (click)="onDelete()"
            [disabled]="!selectedSchedules.length"
    ><cds-icon shape="plus-circle" class="is-solid"></cds-icon> Delete</button>
    <button class="btn btn-sm btn-info-outline"
            (click)="importingModal = true"
    ><cds-icon shape="upload" class="is-solid rotate"></cds-icon> Import</button>
    <button class="btn btn-sm btn-info-outline"><cds-icon shape="upload" class="is-solid"></cds-icon> Export</button>
    <button class="btn btn-sm btn-info-outline" disabled>
      <cds-icon shape="map-marker" class="is-solid"></cds-icon> Publish on Google Maps</button>
  </div>
  
  <clr-datagrid class="full-width" [clrDgLoading]="isLoading" [clrDgRowSelection]="true" [(clrDgSelected)]="selectedSchedules">
    <clr-dg-column [clrDgField]="'name'">Name</clr-dg-column>
    <clr-dg-column [clrDgField]="'type'">Status</clr-dg-column>
    <clr-dg-column [clrDgField]="'uuid'">UUID</clr-dg-column>
    <clr-dg-column [clrDgField]="'created'">Creation date</clr-dg-column>
    <clr-dg-column [clrDgField]="'description'">Description</clr-dg-column>
    <clr-dg-placeholder>We couldn't find any schedules!</clr-dg-placeholder>
    <clr-dg-row *ngFor="let row of schedules$ | async" class="clickable-row" [clrDgItem]="row">
      <clr-dg-action-overflow>
        <button class="action-item" (click)="selectSchedule(row)"><cds-icon shape="cursor-hand-click"></cds-icon> Select</button>
        <button class="action-item" (click)="onEdit(row)"><cds-icon shape="pencil"></cds-icon> Edit</button>
        <button class="action-item" (click)="onDelete(row)"><cds-icon shape="trash"></cds-icon> Delete</button>
      </clr-dg-action-overflow>
      <clr-dg-cell (click)="selectSchedule(row)">{{ row.name }}</clr-dg-cell>
      <clr-dg-cell (click)="selectSchedule(row)">
        <span class="badge" [ngClass]="statusBadgeStyle(row?.type)" translate>{{row?.type?.toUpperCase() | translate}}</span>
      </clr-dg-cell>
      <clr-dg-cell (click)="selectSchedule(row)">{{ row.uuid }}</clr-dg-cell>
      <clr-dg-cell (click)="selectSchedule(row)">{{ row.created | date:'long' }}</clr-dg-cell>
      <clr-dg-cell (click)="selectSchedule(row)">{{ row.description }}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
    </clr-dg-footer>
  </clr-datagrid>
  
  <clr-modal [(clrModalOpen)]="editingModal" class="modal-small">
    <h3 class="modal-title">{{(selectedForEditing ? 'Editing selected schedule' : 'Add new schedule' )}}</h3>
    <div class="modal-body">
      <form class="form" [formGroup]="form">
        <div class="form-group">
          <input type="text" id="name" placeholder="Name" class="form-input" [formControl]="name"/>
        </div>
        <div class="form-group">
          <textarea id="example3" style="height: 6rem" placeholder="Description" class="form-textarea" [formControl]="description"></textarea>
        </div>
      </form>
    </div>
    <div class="modal-footer modal-footer--center">
      <ng-container *ngIf="!selectedForEditing">
        <button class="btn btn-sm btn-primary" action="solid"
            (click)="onSaveEditing()"
      ><cds-icon shape="plus-circle" class="is-solid mr-10"></cds-icon>Add new schedule</button>
      </ng-container>
      <ng-container *ngIf="selectedForEditing">
        <button class="btn btn-sm btn-primary" action="solid"
            (click)="onSaveEditing()"
      ><cds-icon shape="plus-circle" class="is-solid mr-10"></cds-icon>Edit schedule</button>
      </ng-container>
    </div>
  </clr-modal>
  
  
  <clr-modal [(clrModalOpen)]="importingModal">
    <h3 class="modal-title">Import schedule</h3>
    <div class="modal-body">
      <form class="clr-form clr-form-horizontal" [formGroup]="importForm">
        <cds-form-group layout="vertical">
          <cds-file layout="vertical">
            <!-- <label>label</label> -->
            <input type="file" multiple accept="*/*" (change)="onChange($event)"/>
            <!-- <cds-control-message>message text</cds-control-message> -->
          </cds-file>
        </cds-form-group>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="onCancelEditing()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="onImport()">Import</button>
    </div>
  </clr-modal>  
</div>