import { RootLocation } from './app.state.interfaces';

export class SetSchedule {
  static readonly type = '[Location] Set Schedule';
  constructor(public payload: string) {}
}

export class SetRoute {
  static readonly type = '[Location] Set Route';
  constructor(public payload: string) {}
}

export class GetAllSchedules {
  static readonly type = '[Schedules] Get All Schedules';
}

export class SetLocation {
  static readonly type = '[App] Set location';
  constructor(public payload: RootLocation) {}
}

export class SetScheduleId {
  static readonly type = '[App] Set schedule ID';
  constructor(public payload: number) {}
}