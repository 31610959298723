import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LocationStateModel, RootLocation } from './app.state.interfaces';
import { SetLocation, SetRoute, SetSchedule, SetScheduleId } from './app.state.actions';

@State<LocationStateModel>({
  name: 'Location',
})
export class LocationState {
  constructor() {}

  @Selector()
  static scheduleUuid(state: LocationStateModel): string {
    return state.scheduleUuid;
  }

  @Selector()
  static routeUuid(state: LocationStateModel): string {
    return state.routeUuid;
  }

  @Selector()
  static location(state: LocationStateModel): RootLocation {
    return state.location;
  }

  @Selector()
  static getScheduleId(state: LocationStateModel): number {
    return state.scheduleId
  }

  @Action(SetSchedule) setSchedule(ctx: StateContext<LocationStateModel>, action: SetSchedule) {
    ctx.patchState({
      scheduleUuid: action.payload,
    });
  }

  @Action(SetRoute) setRoute(ctx: StateContext<LocationStateModel>, action: SetSchedule) {
    ctx.patchState({
      routeUuid: action.payload,
    });
  }

  @Action(SetLocation) setLocation(ctx: StateContext<LocationStateModel>, action: SetLocation) {
    ctx.patchState({
      location: action.payload,
    });
  }

  @Action(SetScheduleId) setScheduleId(ctx: StateContext<LocationStateModel>, action: SetScheduleId) {
    ctx.patchState({
      scheduleId: action.payload,
    });
  }
}
