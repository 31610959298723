import { Pipe, PipeTransform } from '@angular/core';

const garbageStart = 'http://127.0.0.1:8080/';

@Pipe({
  name: 'fixLink',
})
export class FixLinkPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    return value;
  }
}
