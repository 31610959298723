import { Injectable } from '@angular/core';
import { RESTService } from './rest.service';
import { ITenant } from '../core/itenant';
import { ITenantResponse } from '../core/itenant-response';

@Injectable({
  providedIn: 'root',
})
export class TenantRestService extends RESTService<ITenant, ITenantResponse> {
  endpoint = 'tenants';
  embeddedPropertyName = 'tenants';
}
