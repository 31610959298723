import { Injectable } from '@angular/core';
import { RESTService } from '../rest/rest.service';
import { ISchedule } from '../core/ischedule';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScheduleService extends RESTService<ISchedule, any> {
  endpoint = 'schedules';
  embeddedPropertyName = 'schedules';

  all(params?: any): Observable<ISchedule[]> {
    return this.api.get<ISchedule[]>(`api/gtfs/${this.endpoint}/all`, params);
  }

  create(payload): Observable<ISchedule> {
    return this.api.post<ISchedule>(`api/gtfs/${this.endpoint}`, payload);
  }

  update(payload): Observable<ISchedule> {
    return this.api.put<ISchedule>(`api/gtfs/${this.endpoint}`, payload);
  }

  delete(id): Observable<ISchedule> {
    return this.api.delete(`api/gtfs/${this.endpoint}/${id}`);
  }
}
