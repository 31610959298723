import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SchedulesState } from 'src/app/state/schedules.state';
import { Store } from '@ngxs/store';
import { ISchedule, IShceduleStatus } from 'src/app/core/ischedule';

@Component({
  selector: 'app-schedule-details',
  templateUrl: './schedule-details.component.html',
  styleUrls: ['./schedule-details.component.scss']
})
export class ScheduleDetailsComponent implements OnInit {

  scheduleId$: Observable<ISchedule>; 
  constructor(private store: Store) { }

  ngOnInit(): void {
    this.scheduleId$ = this.store.select(SchedulesState.scheduleByUuid);
  }

  statusBadgeStyle(type: string) {

    switch (type) {
      case IShceduleStatus[IShceduleStatus.PUBLISHED]:
        return 'badge-success';
      case IShceduleStatus[IShceduleStatus.EXPIRED]:
        return 'badge-warning';
      case IShceduleStatus[IShceduleStatus.PUBLISHED_EXPIRED]:
        return 'badge-danger';
      case IShceduleStatus[IShceduleStatus.QUEUE_FOR_PUBLISHING]:
      case IShceduleStatus[IShceduleStatus.DRAFT]:
        return 'badge-info';
      default:
        return '';
    }
  }
}
