import { Component, OnInit } from '@angular/core';
import '@cds/core/icon/register.js';
import { ClarityIcons, homeIcon, viewListIcon, cogIcon, helpIcon, envelopeIcon, logoutIcon } from '@cds/core/icon';
import { Select } from '@ngxs/store';
import { LocationState } from 'src/app/state/location.state';
import { Observable } from 'rxjs';
import { RootLocation, RouteLocations } from 'src/app/state/app.state.interfaces';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss']
})
export class MainNavigationComponent implements OnInit {

  // TODO: style active links based on router path
  @Select(LocationState.location) location$: Observable<RootLocation>;

  readonly Location = RootLocation;
  readonly RouteLocations = RouteLocations;
  constructor(private keycloakService: KeycloakService) {
    ClarityIcons.addIcons(homeIcon, viewListIcon, cogIcon, helpIcon, envelopeIcon, logoutIcon);
  }

  ngOnInit(): void {
  }

  logout() {
    this.keycloakService.logout();
  }

}
