import { Component, OnInit } from '@angular/core';
import { IRoute } from 'src/app/core/iroute';
import { ITrip } from 'src/app/core/itrip';
import { Select } from "@ngxs/store";
import { LocationState } from "../../state/location.state";
import { Observable } from "rxjs";
import { RootLocation } from "../../state/app.state.interfaces";
import { SchedulesState } from "../../state/schedules.state";
import { KeycloakService } from "keycloak-angular";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  routes: IRoute[] = [];
  trips: ITrip[] = [];

  @Select(LocationState.location) location$: Observable<RootLocation>;
  @Select(SchedulesState.scheduleName) scheduleName$: Observable<string>;
  @Select(LocationState.routeUuid) routeUuid$: Observable<string>;

  constructor(private keycloak: KeycloakService) {}

  ngOnInit() {}

  logout() {
    this.keycloak.logout().then();
  }
}
