import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { IListResponse } from '../core/ilist-response';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RESTService<SingularType, PluralType extends IListResponse<SingularType, string>> {
  protected readonly endpoint: string;
  protected embeddedPropertyName: string;
  // @FIXME

  private readonly tenantName = environment.tenantName;
  protected readonly segment = `api/gtfs/${this.tenantName}`;

  constructor(protected api: ApiService) {}

  get(id: string, params?: any): Observable<SingularType> {
    return this.api.get<SingularType>(`${this.segment}/${this.endpoint}/${id}`, params);
  }

  getAll(params?: any): Observable<PluralType> {
    return this.api.get<PluralType>(`${this.segment}/${this.endpoint}`, params);
  }

  extractList(listResponse: PluralType): SingularType[] {
    return listResponse._embedded[this.embeddedPropertyName];
  }

  getExtractedList = (params?: any): Observable<SingularType[]> => {
    return this.getAll(params).pipe(map((response) => this.extractList(response)));
  };

  getRoot<T>(params?: any): Observable<T> {
    return this.api.get<T>(`${this.segment}/${this.endpoint}`, params);
  }
}
