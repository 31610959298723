export interface IScheduleCore {
  uuid: string;
  name: string;
}

export interface ISchedule extends IScheduleCore {
  id: number;
  name: string;
  description: string;
  type: string;
  created: number[]; // todo why it is an array of numbers? is it convenient?
}

export enum IShceduleStatus {
  "DRAFT",
  "PUBLISHED",
  "QUEUE_FOR_PUBLISHING",
  "EXPIRED",
  "PUBLISHED_EXPIRED"
}
