<div class="footer">
    <div class="footer__logo">
        <img src="assets/images/smart-schedules-logo.svg" alt="Smart Schedules Logo">
    </div>
    <p class="footer__copyrights">
        Copyright Smart Schedules. All Rights Reserved
    </p>
    <ul class="footer__links">
        <li><a href="">Contact us</a></li>
        <li>|</li>
        <li><a href="">Settings</a></li>
        <li>|</li>
        <li><a href="">Privacy</a></li>
        <li>|</li>
        <li><a href="">FAQ</a></li>
    </ul>
    <div class="footer__image">
        <img src="assets/images/footer-image.svg" alt="Trams and buses">
    </div>
</div>