import { Component, OnInit } from '@angular/core';
import { TenantRestService } from '../../rest/tenant-rest.service';
import { Observable } from 'rxjs';
import { ITenant } from '../../core/itenant';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss'],
})
export class TenantsComponent implements OnInit {
  tenants$: Observable<ITenant[]>;

  constructor(private tenants: TenantRestService) {}

  ngOnInit() {
    this.tenants$ = this.tenants.getExtractedList();
  }
}
