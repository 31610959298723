<div class="main-navigation">
    <img class="main-navigation__logo" src="assets/logo.svg"  alt="Smart Schedules Logo"/>
    <ul class="main-navigation__menu">
        <li class="main-navigation__menu-item" [class.active]="(location$ | async) == Location.schedules">
            <a href="" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-top-right">
                <cds-icon size="lg" shape="home" class="icon"></cds-icon>
                <span class="tooltip-content">Home</span>
            </a>
        </li>
        <li class="main-navigation__menu-item" [class.active]="RouteLocations.includes((location$ | async))">
            <a disabled role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-top-right">
                <cds-icon size="lg" shape="view-list" class="icon"></cds-icon>
                <span class="tooltip-content">List</span>
            </a>
        </li>
        <li class="main-navigation__menu-item" [class.active]="(location$ | async) == Location.googleOnboarding">
            <a disabled role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-top-right">
                <cds-icon size="lg" shape="cog" class="icon"></cds-icon>
                <span class="tooltip-content">Settings</span>
            </a>
        </li>
        <li class="main-navigation__menu-item">
            <a href="" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-top-right">
                <cds-icon size="lg" shape="help" class="icon"></cds-icon>
                <span class="tooltip-content">Help</span>
            </a>
        </li>
        <li class="main-navigation__menu-item">
            <a href="" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-top-right">
                <cds-icon size="lg" shape="envelope" class="icon"></cds-icon>
                <span class="tooltip-content">Contact</span>
            </a>
        </li>
    </ul>
    <ul class="main-navigation__menu main-navigation__menu--logout">
        <li class="main-navigation__menu-item">
            <a href="" (click)="logout()" role="tooltip" aria-haspopup="true" class="tooltip tooltip-sm tooltip-top-right">
                <cds-icon size="lg" shape="logout" class="icon"></cds-icon>
                <span class="tooltip-content">Logout</span>
            </a>
        </li>
    </ul>
</div>
