import { Pipe, PipeTransform } from '@angular/core';

const OUTBOUND = 'outbound';
const INBOUND = 'inbound';

@Pipe({
  name: 'direction',
})
export class DirectionPipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    if (+value === 0) {
      return OUTBOUND;
    }

    if (+value === 1) {
      return INBOUND;
    }

    return '';
  }
}
