import { Component, OnDestroy, OnInit } from '@angular/core';
import { ScheduleService } from '../schedule.service';
import { Observable, Subject } from 'rxjs';
import { ISchedule, IShceduleStatus } from '../../core/ischedule';
import { Select, Store } from '@ngxs/store';
import { GetAllSchedules, SetLocation, SetSchedule, SetScheduleId } from '../../state/app.state.actions';
import { SchedulesState } from '../../state/schedules.state';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { finalize, map, takeUntil } from 'rxjs/operators';
import { RootLocation } from '../../state/app.state.interfaces';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import '@cds/core/icon/register.js';
import { ClarityIcons, cursorHandClickIcon, mapIcon, mapMarkerIcon, pencilIcon, plusCircleIcon, trashIcon, uploadIcon, userIcon } from '@cds/core/icon';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss'],
})
export class SchedulesComponent implements OnInit, OnDestroy {

  /*
  *
  * - Kolumny grida (Numer porządkowy, uuid, nazwa, status, data utworzenia, opis
  * - Możliwe statusy to: [DRAFT, PUBLISHED, QUEUE_FOR_PUBLISHING, EXPIRED, PUBLISHED_EXPIRED]
  * - Przycisk do importu nowego rozkładu
  * - Przycisk do exportu rozkładu
  * - Przycisk do manualnego utworzenia nowego rozkładu
  * - Przycisk do kasowania rozkładu jazdy
  * - Przycisk do Google Maps Onboarding Process (widoczny jeśli przewoźnik jeszcze go nie przeszedł)
  * - Przycisk do publikacji rozkładu jazdy do Google Maps (widoczny po przejściu Google Maps Onboarding Process)
  * - Możliwość edycji nazwy oraz opisu rozkładu
  *
  * */


  @Select(SchedulesState.schedules) schedules$: Observable<ISchedule[]>;
  mappedSchedules$;
  private ngUnsubscribe$: Subject<void> = new Subject();
  selectedForEditing: ISchedule;
  editingModal = false;
  importingModal = false;
  form: FormGroup;
  importForm: FormGroup;
  isLoading = false;

  selectedSchedules: ISchedule[] = [];

  constructor(
    private scheduleService: ScheduleService,
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    ClarityIcons.addIcons(plusCircleIcon, mapIcon, uploadIcon, mapMarkerIcon, cursorHandClickIcon, pencilIcon, trashIcon);
  }

  ngOnInit() {
    this.store.dispatch(new SetLocation(RootLocation.schedules));

    this.mappedSchedules$ = this.schedules$.pipe(map((item, position) => ({...item, position})));

    this.route.paramMap
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((params) => this.onParamsChange(params));

    this.form = this.formBuilder.group({
      name: new FormControl(''),
      description: new FormControl('')
    });
    this.importForm = this.formBuilder.group({
      file: new FormControl(null)
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
  }

  statusBadgeStyle(type: string) {
    // TODO: Corresponding statuses with Figma layout
    switch (type) {
      case IShceduleStatus[IShceduleStatus.PUBLISHED]:
        return 'badge-success';
      case IShceduleStatus[IShceduleStatus.EXPIRED]:
        return 'badge-warning';
      case IShceduleStatus[IShceduleStatus.PUBLISHED_EXPIRED]:
        return 'badge-danger';
      case IShceduleStatus[IShceduleStatus.QUEUE_FOR_PUBLISHING]:
      case IShceduleStatus[IShceduleStatus.DRAFT]:
        return 'badge-info';
      default:
        return '';
    }
  }

  onDelete() {
    console.warn(this.selectedSchedules);
    // TODO: Endpoint to delete multiple rows
    // this.scheduleService.delete(row.id)
    //   .subscribe(deleteResponse => console.log(deleteResponse));
  }

  onEdit(row: ISchedule) {
    this.selectedForEditing = row;
    this.selectedSchedules = [row];
    this.form.patchValue({name: row.name, description: row.description || '', no: row.id});
    this.editingModal = true;
  }

  selectSchedule(row: ISchedule) {
    this.store.dispatch(new SetScheduleId(row.id));
    this.router.navigate([row.uuid, 'routes'], {queryParams: { page: 1, pageSize: 10 }});
  }

  get name() {
    return this.form.get('name');
  }

  get description() {
    return this.form.get('description');
  }

  get no(): AbstractControl {
    return this.form.get('no');
  }

  private onParamsChange = (params: ParamMap) => {
    const uuid = params.get('scheduleUuid');
    this.store.dispatch(new SetSchedule(uuid));
  };

  onCancelEditing() {
    delete this.selectedForEditing;
    this.editingModal = false;
    this.importingModal = false;
  }

  onSaveEditing() {
    const savingStream = (this.selectedForEditing)
      ? this.scheduleService.update({...this.selectedForEditing, ...this.form.getRawValue()}).pipe(finalize(() => {
        this.reload();
      }))
      : this.scheduleService.create({...this.selectedForEditing, ...this.form.getRawValue()}).pipe(finalize(() => {
        this.reload();
      }))
    savingStream.subscribe(savingResponse => console.log(savingResponse));
    delete this.selectedForEditing;
    this.editingModal = false;
  }

  reload() {
    this.store.dispatch(new GetAllSchedules());
  }

  onChange($event: Event) {
    console.log($event);
  }

  onImport() {

  }

  addNewSchedule() {
    this.form.reset();
    this.editingModal = true;
  }
}
