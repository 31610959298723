import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RootLocation } from 'src/app/state/app.state.interfaces';
import { LocationState } from 'src/app/state/location.state';

@Component({
  selector: 'app-routes-navigation',
  templateUrl: './routes-navigation.component.html',
  styleUrls: ['./routes-navigation.component.scss'],
})
export class RoutesNavigationComponent implements OnDestroy {
  // @Select(LocationState.location) location$: Observable<RootLocation>;
  @Select(LocationState.scheduleUuid) scheduleUuid$: Observable<string>;
  @Select(LocationState.routeUuid) routeUuid$: Observable<string>;

  readonly Location = RootLocation;
  location$: string = '';

  routeSubscription: Subscription;
  
  constructor(private router: Router) {
    this.routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((_: NavigationEnd) => {
      this.location$ = _.url.split('/').pop();
    })
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}
