import { Selector, State } from '@ngxs/store';
import { AppStateModel } from './app.state.interfaces';
import { SchedulesState } from './schedules.state';
import { RouterState } from '@ngxs/router-plugin';
import { RouterStateSnapshot } from '@angular/router';
import { DEFAULT_PAGINATION_PARAMS } from '../core/default-pagination-params';
import { LocationState } from './location.state';
import { get as _get } from 'lodash';

@State<AppStateModel>({
  name: 'AppState',
  // TODO extend other states
  children: [SchedulesState, LocationState],
})
export class AppState {
  @Selector([RouterState.state])
  static page(state: AppStateModel, routerState: RouterStateSnapshot): number {
    const page = routerState.root.queryParamMap.get('page');
    return page ? +page - 1 : DEFAULT_PAGINATION_PARAMS.page;
  }

  @Selector([RouterState.state])
  static pageSize(state: AppStateModel, routerState: RouterStateSnapshot): number {
    const size = _get(routerState, 'root.queryParamMap.pageSize');
    return size ? +size : DEFAULT_PAGINATION_PARAMS.size;
  }
}
