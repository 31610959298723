import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialBarrelModule } from './material-barrel/material-barrel.module';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { HomeModule } from './home/home.module';
import { AgmCoreModule } from '@agm/core';

import { ClarityModule } from '@clr/angular';
import { initializer } from './rest/keycloak-initializer';
import { KeycloakService } from 'keycloak-angular';
import { NgxsModule } from '@ngxs/store';
import { allStates } from './state/all-states';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';

import localePl from '@angular/common/locales/pl';
import { registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgSelectModule } from '@ng-select/ng-select';
registerLocaleData(localePl);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AgmCoreModule.forRoot({ apiKey: environment.gMapsApiKey }),
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxsModule.forRoot([...allStates], {developmentMode: true}),
    NgxsRouterPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    HomeModule,
    AppRoutingModule,
    MaterialBarrelModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ClarityModule,
    NgxMaterialTimepickerModule,
    NgSelectModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true,
    },
    // {
    //   provide: LOCALE_ID,
    //   useValue: 'pl-PL',
    // },
    KeycloakService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private translate: TranslateService,

  ) {
    this.translate.setDefaultLang('en');
    translate.use('en');
  }
}
