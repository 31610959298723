<mat-list role="list">
  <mat-list-item role="listitem" [class.active]="(location$ | async) === Location.schedules">
    <a mat-button [routerLink]="['/']">Schedules</a>
  </mat-list-item>
  <mat-list-item
    role="listitem"
    *ngIf="scheduleUuid$ | async"
    [class.active]="(location$ | async) === Location.routes"
  >
    <a mat-button [routerLink]="['/', scheduleUuid$ | async, 'routes']" class="level-1">Routes</a>
  </mat-list-item>
  <mat-list-item
    role="listitem"
    *ngIf="(scheduleUuid$ | async)"
    [class.active]="(location$ | async) === Location.trips"
  >
    <a
      mat-button
      [routerLink]="['/', scheduleUuid$ | async, 'trips']"
      class="level-1"
      [queryParams]="{ route: routeUuid$ | async }"
      >Trips</a
    >
  </mat-list-item>
  <mat-list-item
    role="listitem"
    *ngIf="scheduleUuid$ | async"
    [class.active]="(location$ | async) === Location.stops"
  >
    <a mat-button [routerLink]="['/', scheduleUuid$ | async, 'stops']" class="level-1">Stops</a>
  </mat-list-item>
  <mat-list-item
    role="listitem"
    *ngIf="scheduleUuid$ | async"
    [class.active]="(location$ | async) === Location.shapes"
  >
    <a mat-button [routerLink]="['/', scheduleUuid$ | async, 'shapes']" class="level-1">Shapes</a>
  </mat-list-item>
  <mat-list-item
    role="listitem"
    *ngIf="scheduleUuid$ | async"
    [class.active]="(location$ | async) === Location.calendar"
  >
    <a mat-button [routerLink]="['/', scheduleUuid$ | async, 'calendar']" class="level-1">Calendar</a>
  </mat-list-item>
  <mat-list-item
    role="listitem"
    *ngIf="scheduleUuid$ | async"
    [class.active]="(location$ | async) === Location.stopTimes"
  >
    <a mat-button [routerLink]="['/', scheduleUuid$ | async, 'stop-times']" class="level-1">Stop Times</a>
  </mat-list-item>
  <mat-list-item>
    <button mat-button (click)="logout()">Logout</button>
  </mat-list-item>
</mat-list>
