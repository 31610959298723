import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { RoutesNavigationComponent } from './routes-navigation/routes-navigation.component';
import { RouterModule } from '@angular/router';
import { CommonUiModule } from '../common-ui/common-ui.module';
import { MaterialBarrelModule } from '../material-barrel/material-barrel.module';
import { TenantsModule } from '../tenants/tenants.module';
import { ClrIconModule } from '@clr/angular';
import { ScheduleDetailsComponent } from './schedule-details/schedule-details.component';



@NgModule({
  declarations: [MainNavigationComponent, RoutesNavigationComponent, ScheduleDetailsComponent],
  imports: [
    CommonModule,
    RouterModule,
    CommonUiModule, 
    MaterialBarrelModule,
    TenantsModule, 
    ClrIconModule
  ],
  exports: [MainNavigationComponent, RoutesNavigationComponent, ScheduleDetailsComponent]
})
export class SharedModule { }
