<div class="page-navigation">
    <div class="page-navigation__item"
    *ngIf="scheduleUuid$ | async"
    [class.active]="location$?.includes(Location.routes)">
        <a class="link" [routerLink]="['/', scheduleUuid$ | async, 'routes']">Routes</a>
    </div>
    <div class="page-navigation__item"
    *ngIf="(scheduleUuid$ | async)"
    [class.active]="location$.includes(Location.trips)">
        <a class="link" [routerLink]="['/', scheduleUuid$ | async, 'trips']"
        >Trips</a>
    </div>
    <div class="page-navigation__item"
    *ngIf="scheduleUuid$ | async"
    [class.active]="location$?.includes(Location.stops)">
        <a class="link" [routerLink]="['/', scheduleUuid$ | async, 'stops']">Stops</a>
    </div>
    <div class="page-navigation__item"
    *ngIf="scheduleUuid$ | async"
    [class.active]="location$?.includes(Location.shapes)">
        <a class="link" [routerLink]="['/', scheduleUuid$ | async, 'shapes']">Shapes</a>
    </div>
    <div class="page-navigation__item"
    *ngIf="scheduleUuid$ | async"
    [class.active]="location$?.includes(Location.calendar)">
        <a class="link" [routerLink]="['/', scheduleUuid$ | async, 'calendar']">Calendar</a>
    </div>
    <div class="page-navigation__item"
    *ngIf="scheduleUuid$ | async"
      [class.active]="location$?.includes(Location.stopTimesRoute)">
      <a class="link" [routerLink]="['/', scheduleUuid$ | async, 'stop-times']">Stop Times</a>
    </div>
</div>