import { ISchedule } from '../core/ischedule';

export enum RootLocation {
  schedules = 'schedules',
  routes = 'routes',
  trips = 'trips',
  stops = 'stops',
  shapes = 'shapes',
  calendar = 'calendar',
  stopTimes = 'stop times',
  stopTimesRoute = 'stop-times',
  googleOnboarding = 'google-onboarding-process'
}

export const RouteLocations = ['routes', 'trips', 'stops', 'shapes', 'calendar', 'stop times']

export interface AppStateModel {
  scheduleName: string;
  scheduleUuid: string;
}

export interface SchedulesStateModel {
  scheduleName: string;
  scheduleUuid: string;
  loading: boolean;
  schedules: ISchedule[];
}

export interface LocationStateModel {
  scheduleUuid: string;
  routeUuid?: string;
  tripUuid?: string;
  location?: RootLocation;
  scheduleId?: number;
}
