import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { EMPTY, from, Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

const httpPrefix = 'http://127.0.0.1:8080/';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  readonly apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router, private keycloak: KeycloakService) {}

  get = <T>(path: string, params?: any) => {
    const headersObservable = from(this.keycloak.getToken());

    return headersObservable.pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

        return this.http
          .get<T>(this.buildPath(path), { params, headers })
          .pipe(catchError(this.redirectOnUnauthorized));
      }),
    );
  };

  post = <T>(path: string, body: any,  params?: any) => {
    const headersObservable = from(this.keycloak.getToken());

    return headersObservable.pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

        return this.http
          .post<T>(this.buildPath(path), body, { params, headers })
          .pipe(catchError(this.redirectOnUnauthorized));
      }),
    );
  };

  put = <T>(path: string, body: any, params?: any) => {
    const headersObservable = from(this.keycloak.getToken());

    return headersObservable.pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

        return this.http
          .put<T>(this.buildPath(path), body, { params, headers })
          .pipe(catchError(this.redirectOnUnauthorized));
      }),
    );
  };

  delete = <T>(path: string, params?: any) => {
    const headersObservable = from(this.keycloak.getToken());

    return headersObservable.pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ Authorization: `Bearer ${token}` });

        return this.http
          .delete<T>(this.buildPath(path), { params, headers })
          .pipe(catchError(this.redirectOnUnauthorized));
      }),
    );
  };

  private redirectOnUnauthorized = (e: HttpErrorResponse): Observable<any> => {
    if (e.status === 401) {
      this.keycloak.logout();
    }

    return EMPTY;
  };

  private sanitizePath = (path: string): string => {
    if (!path.includes(httpPrefix)) {
      return path;
    }

    const split = path.split(httpPrefix);
    return split[1];
  };

  private buildPath = (path: string): string =>
    path.startsWith('http:') || path.startsWith('https:')
      ? path
      : `${this.apiUrl}/${this.sanitizePath(path)}`;
}
